import { Module } from "vuex";

import { api } from "@/util/axios";

const PAYMENTS_API_URL_V2 = process.env.VUE_APP_PAYMENTS_API_V2_URL;

type PayoutState = {
  payoutList: any[];
  gateway: undefined;
  mpesaGateway: undefined;
  stripeBalance: undefined;
  wallet: undefined;
  bankAccount: undefined;
};

const payout: Module<PayoutState, unknown> = {
  namespaced: true,
  state: () => ({
    payoutList: [],
    gateway: undefined,
    stripeBalance: undefined,
    wallet: undefined,
    mpesaGateway: undefined,
    bankAccount: undefined,
  }),
  getters: {
    getPayout: (state) => (payoutId: string) =>
      state.payoutList.find((c) => c._id === payoutId),
    payoutList: (state) => state.payoutList,
    gateway: (state) => state.gateway,
    stripeBalance: (state) => state.stripeBalance,
    wallet: (state) => state.wallet,
    mpesaGateway: (state) => state.mpesaGateway,
    bankAccount: (state) => state.bankAccount,
  },
  mutations: {
    SET_PAYOUTS: (state, list) => {
      state.payoutList = list;
    },
    SET_GATEWAY: (state, gw) => {
      state.gateway = gw;
    },
    SET_STRIPE_BALANCE: (state, bal) => {
      state.stripeBalance = bal;
    },
    SET_WALLET: (state, bal) => {
      state.wallet = bal;
    },
    SET_MPESA_GATEWAY: (state, gw) => {
      state.mpesaGateway = gw;
    },
    SET_BANK_ACCOUNT: (state, acc) => {
      state.bankAccount = acc;
    },
  },
  actions: {
    fetchBankConfig(context, vendor) {
      return api.get(`/v2/money/bank/account/${vendor}`).then((response) => {
        const { bankAccount } = response.data;
        context.commit("SET_BANK_ACCOUNT", bankAccount);
      });
    },
    createBankAccount({ commit }, data) {
      return api
        .post(`/v2/money/bank/account`, { ...data })
        .then((response) => {
          const { bankAccount } = response.data;
          commit("SET_BANK_ACCOUNT", bankAccount);
          return response.data;
        });
    },
    updateBankAccount({ commit }, data) {
      const { vendor, returnUrl } = data;
      return api
        .put(`/v2/money/bank/account/${vendor}`, { returnUrl })
        .then((response) => {
          const { bankAccount } = response.data;
          commit("SET_BANK_ACCOUNT", bankAccount);
          return response.data;
        });
    },
    createMpesaPayout(context, data) {
      const { vendorId, ...payload } = data;
      return api
        .post(`/v2/payout/${vendorId}`, { ...payload })
        .then((res) => {
          if (res.data) {
            context.dispatch(
              "setToast",
              {
                title: "Payout success!",
                type: "success",
                text: res.data.message,
              },
              { root: true }
            );
          }
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.message,
            },
            { root: true }
          );
        });
    },
    createBankPayout(context, data) {
      return api
        .post(`/v2/money/bank/transfer`, { ...data })
        .then((res) => {
          if (res.data) {
            context.dispatch(
              "setToast",
              {
                title: "Payout success!",
                type: "success",
                text: res.data.message,
              },
              { root: true }
            );
          }
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.message,
            },
            { root: true }
          );
        });
    },
    createStripePayout(context, data) {
      return api
        .post(`/v2/money/stripe/payout`, { ...data })
        .then((res) => {
          if (res.data) {
            context.dispatch(
              "setToast",
              {
                title: "Payout success!",
                type: "success",
                text: res.data.message,
              },
              { root: true }
            );
          }

          return res.data;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.error?.message || "Stripe Payout Failed",
            },
            { root: true }
          );
        });
    },
    fetchWallet(context, params = "") {
      api
        .get(`/v1/business/${params}/wallet`)
        .then((response) => {
          context.commit("SET_WALLET", response.data.wallet);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchStripeBalance(context, params = "") {
      api
        .get(`/v2/money/stripe/account/${params}/balance`)
        .then((response) => {
          if (response.data.message) {
            context.dispatch(
              "setToast",
              {
                title: "Request failed!",
                type: "error",
                text: response?.data?.message,
              },
              { root: true }
            );
            return;
          }
          context.commit("SET_STRIPE_BALANCE", response.data.balance);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchGateway(context, params = "") {
      api
        .get(`/gateway/${params}`, { baseURL: PAYMENTS_API_URL_V2 })
        .then((response) => {
          if (!response.data.gateways.length) {
            return;
          }
          const gateway = response.data.gateways.find(
            (g: any) => g.type === "Stripe"
          );
          const mpesaGateway = response.data.gateways.find(
            (g: any) => g.type === "Mpesa"
          );
          context.commit("SET_GATEWAY", gateway);
          context.commit("SET_MPESA_GATEWAY", mpesaGateway);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchPayoutList(context, params = "") {
      api
        .get(`/v2/payout${params}`)
        .then((response) => {
          context.commit("SET_PAYOUTS", response.data.payoutPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    updatePayoutConfig(context, { vendor, data }) {
      api
        .put(`/v2/money/stripe/account/${vendor}/configure`, { ...data })
        .then((response) => {
          console.log("data: ", response.data);
          context.commit("SET_PAYOUT_PAGE", response.data.payoutPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default payout;
