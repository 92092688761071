
























import Vue from "vue";

import smsBundleStoreModule from "@/store/modules/smsBundle";
import { createNamespacedHelpers } from "vuex";

const { mapGetters: smsBundleGetters, mapActions: smsBundleActions } =
  createNamespacedHelpers("SMS_BUNDLE_LIST");

export default Vue.extend<any, any, any, any>({
  name: "SMSBundleForm",
  data: () => ({
    valid: false,
    smsBundle: {},
  }),
  computed: {
    ...smsBundleGetters(["smsBundlePage"]),
  },
  created() {
    this.fetchSMSBundleList(`?status=active`);
  },
  methods: {
    ...smsBundleActions(["fetchSMSBundleList"]),
    validateForm() {
      const valid = (
        this.$refs.form as Element & {
          validate: () => boolean;
        }
      )?.validate();

      if (!valid) return;
      this.$emit("data-valid", this.smsBundle);
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SMS_BUNDLE_LIST")) {
      this.$store.registerModule("SMS_BUNDLE_LIST", smsBundleStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SMS_BUNDLE_LIST");
  },
});
