















































































































import Vue from "vue";
import axios from "axios";

import { PHONE_REGEX } from "@/util/constants";
import { Business, Role, User } from "@/types";
import { createNamespacedHelpers } from "vuex";
import paymentVendorGateway from "@/store/modules/paymentVendorGateway";
// import { loadStripe } from "@stripe/stripe-js";

// const stripePubKey = process.env.VUE_APP_STRIPE_PUB_KEY;

const { mapActions: _gatewayActions } =
  createNamespacedHelpers("_PAYMENT_GATEWAY");

export default Vue.extend<any, any, any, any>({
  name: "addStripeVendorForm",
  props: ["isOnbordingComplete"],
  data: () => ({
    striptId: "",
    isLoading: false,
    form: {
      name: "",
      country: "Kenya",
      business_type: "individual",
      primary_phone: "",
      primary_email: "",
      account_number: "",
      account_routing_number: "",
      account_holder_name: "",
      site_url: "",
    },
    errors: "",
    emailRules: [(v: string) => !!v || "Email is required"],
    countryRules: [(v: string) => !!v || "Country is required"],
    bankNameRules: [(v: string) => !!v || "Bank name is required"],
    currencyRules: [(v: string) => !!v || "Currency is required"],
    accountHolderNameRules: [
      (v: string) => !!v || "Account holder name is required",
    ],
    accountNumberRules: [(v: string) => !!v || "Account number is required"],
    routingNumberRules: [(v: string) => !!v || "routing number  is required"],
    companyNameRules: [(v: string) => !!v || "company name  is required"],
    companyPhoneRules: [
      (v: string) => !!v || "Phone number  is required",
      (v: string) => PHONE_REGEX.test(v) || "A valid Phone number is required",
    ],
    companyTaxIdRules: [(v: string) => !!v || "Tax id is required"],
    addressCityRules: [(v: string) => !!v || "City is required"],
    addressline1Rules: [(v: string) => !!v || "Line1 is required"],
    addressPostalCodeRules: [(v: string) => !!v || "Postal Code is required"],
    addressStateRules: [(v: string) => !!v || "State is required"],
    mccRules: [(v: string) => !!v || "MCC is required"],
    urlRules: [(v: string) => !!v || "url is required"],
    firstNameRules: [(v: string) => !!v || "name is required"],
    lastNameRules: [(v: string) => !!v || "Last Name is required"],
  }),
  mounted() {
    // console.log("this.$store", this.$store);
    // console.log("props", this.$props.isOnbordingComplete);
    //this.isStripeSetupForm();
  },
  watch: {
    vendor: {
      handler() {
        if (this.vendor) {
          this.form.name = this.vendor.name;
          this.form.primary_email = (this.role.user as User).email;
          this.form.primary_phone = (this.role.user as User).phoneNumber || "";
        }
      },
    },
  },
  created() {
    if (this.vendor) {
      this.form.name = this.vendor.name;
      this.form.primary_email = (this.role.user as User).email;
      this.form.primary_phone = (this.role.user as User).phoneNumber || "";
    }
  },
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
    vendor(): Business {
      return this.role.business as Business;
    },
  },
  methods: {
    ..._gatewayActions(["stripeOnboard"]),
    validateForm() {
      const valid = (
        this.$refs.addStripeVendorForm as Element & {
          validate: () => boolean;
        }
      )?.validate();

      if (!valid) return;

      this.createVendor();
    },
    resetForm() {
      this.form = {
        name: "",
        country: "Kenya",
        business_type: "individual",
        primary_phone: "",
        primary_email: "",
        account_number: "",
        account_routing_number: "",
        account_holder_name: "",
        site_url: "",
      };
      (
        this.$refs.addStripeVendorForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    async createVendor() {
      if (!this.vendor) {
        return;
      }

      // const stripe = await loadStripe(stripePubKey);

      // const {
      //   account_number,
      //   account_routing_number,
      //   account_holder_name,
      //   country,
      //   site_url: siteUrl,
      //   business_type: type,
      //   primary_phone: phoneNumber,
      //   primary_email: email,
      //   name,
      // } = this.form;
      // if (stripe) {
      //   this.isLoading = true;
      //   const { error, token } = await stripe.createToken("bank_account", {
      //     country: country == "Kenya" ? "KE" : "AU",
      //     currency: country == "Kenya" ? "KES" : "AUD",
      //     account_holder_name,
      //     account_holder_type: type,
      //     routing_number: account_routing_number,
      //     account_number: account_number,
      //   });

      //   if (error) {
      //     this.isLoading = false;
      //     this.$store.dispatch(
      //       "setToast",
      //       {
      //         title: "Request Failed",
      //         type: "error",
      //         text:
      //           (error as unknown as Error).message ||
      //           "Stripe Account Not Created, check your account details",
      //       },
      //       { root: true }
      //     );
      //     return;
      //   }

      //   if (!token) {
      //     return;
      //   }

      //   this.stripeOnboard({
      //     returnUrl: window.location.href,
      //     vendor: this.vendor._id,
      //     refreshUrl: window.location.href,
      //     externalAccount: token.id,
      //     countryCode: country == "Kenya" ? "KE" : "AU",
      //   })
      //     .then((result) => {
      //       this.$emit("data-saved", result);
      //     })
      //     .finally(() => (this.isLoading = false));
      //   return;
      // } else {
      //   this.$store.dispatch(
      //     "setToast",
      //     {
      //       title: "Request Failed",
      //       type: "error",
      //       text: "Stripe Timeout",
      //     },
      //     { root: true }
      //   );
      // }
    },
    recreateOnboarding() {
      // console.log("vendors data",this.vendor)
      // console.log('url',process.env.VUE_APP_PAYMENTS_API_V1_URL)
      let stripeId = localStorage.getItem("stripeId");
      let payload = {
        user_id: this.$store.getters.user._id,
        refresh_url: window.location.href,
        return_url: window.location.href,
        striptId: stripeId,
      };

      axios
        .post(
          process.env.VUE_APP_PAYMENTS_API_V1_URL +
            "/paymentVendor/create/stipeReconnect",
          payload
        )
        .then((response) => {
          // console.log("check response", response);
          if (response.data.success === false) {
            // this.errors=response.data.message
            this.$store.dispatch(
              "setToast",
              {
                title: "Request failed!",
                type: "error",
                text: response.data.message,
              },
              { root: true }
            );
          } else {
            this.$store.dispatch(
              "setToast",
              {
                title: "Success",
                type: "success",
                text: response.data.message,
              },
              { root: true }
            );
            this.$emit("data-saved", true);
            this.$emit("isStripeSetup", true);

            // console.log("authlink", response.data.data.authLink);
            // console.log("stripeId", response.data.data.paymentgateway_id);

            if (
              response.data.data.paymentgateway_id !== "" &&
              response.data.data.paymentgateway_id !== null &&
              response.data.data.paymentgateway_id !== undefined
            ) {
              localStorage.setItem(
                "stripeId",
                response.data.data.paymentgateway_id
              );
            }
            if (
              response.data.data.authLink !== "" &&
              response.data.data.authLink !== null &&
              response.data.data.authLink !== undefined
            ) {
              window.location.href = response.data.data.authLink;
            }
          }
        })
        .catch((error) => {
          //  console.log(JSON.stringify(error))
          // console.log(error.message);
          // this.errors=error.message
          this.$store.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.message,
            },
            { root: true }
          );
        });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("_PAYMENT_GATEWAY")) {
      this.$store.registerModule("_PAYMENT_GATEWAY", paymentVendorGateway);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("_PAYMENT_GATEWAY");
  },
});
