





















































































import Vue from "vue";
import PayoutStoreModule from "@/store/modules/payout/payout";
import { createNamespacedHelpers } from "vuex";
import { Business, Role } from "@/types";
import { sendOtp, verifyOtp } from "@/util/otp";
import PaymentGatewayStoreModule from "@/store/modules/paymentVendorGateway";

const { mapActions: bankActions, mapGetters: bankGetters } =
  createNamespacedHelpers("PAYOUT_CONFIG");

const { mapActions: gatewayActions, mapGetters: gatewayGatters } =
  createNamespacedHelpers("GATEWAY_CONFIG");

export default Vue.extend<any, any, any, any>({
  name: "BankPayout",
  data: () => ({
    isLoading: false,
    confirmDialog: false,
    config: {
      bank_account_name: "",
      bank_account: "",
      bank_code: "",
      country: "KE",
    },
    confirmToken: "",
    bankNameRules: [(v: string) => !!v || "Account Holder Name is required"],
    bankAccountRules: [(v: string) => !!v || "Account Number is required"],
    bankCodeRules: [(v: string) => !!v || "Bank Code is required"],
    countryCodeRules: [(v: string) => !!v || "Country is required"],
    codeRules: [(v: string) => !!v || "Confirmation code is required"],
    sendingOtp: false,
    otp: "",
  }),
  computed: {
    ...bankGetters(["bankAccount"]),
    ...gatewayGatters(["gateways"]),
    account(): any {
      return this.bankAccount;
    },
    role(): Role {
      return this.$store.getters.role;
    },
    vendor(): Business {
      return this.role.business;
    },
    mpesaGatewayData(): any {
      return this.gateways.gateways.mpesaGateway;
    },
  },
  watch: {
    role() {
      if (this.role) {
        this.init();
      }
    },
    account() {
      if (this.account) {
        const { name, bankCode, accountNumber, countryCode } = this.account;
        this.config = {
          bank_account_name: name,
          bank_code: bankCode,
          bank_account: accountNumber,
          country: countryCode,
        };
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...bankActions([
      "fetchBankConfig",
      "createBankAccount",
      "updateBankAccount",
    ]),
    ...gatewayActions(["fetchPaymentGateways"]),
    init() {
      if (this.vendor) {
        this.fetchBankConfig(this.vendor._id);
        const bid = this.vendor._id;
        // this.fetchStripeBalance(bid);
        this.fetchPaymentGateways(bid);
      }
    },
    getOtp() {
      if (this.sendingOtp) return;
      if (this.mpesaGatewayData && this.mpesaGatewayData.phoneNumber) {
        this.sendingOtp = true;
        sendOtp(this.mpesaGatewayData.phoneNumber)
          .then(({ data }) => {
            this.confirmToken = data.token;
          })
          .finally(() => (this.sendingOtp = false));
      }
    },
    validateForm() {
      const valid = (
        this.$refs.bankConfigForm as Element & {
          validate: () => boolean;
        }
      )?.validate();

      if (!valid) return;

      if (!this.vendor) return;

      this.confirmDialog = true;

      this.getOtp();
    },
    async updateConfig() {
      const valid = (
        this.$refs.bankConfigForm as Element & {
          validate: () => boolean;
        }
      )?.validate();

      if (!valid) return;

      const accepted = await verifyOtp(this.otp, this.confirmToken);

      console.log(accepted);

      if (!accepted) {
        this.$store.dispatch(
          "setToast",
          {
            title: "Invalid OTP!",
            type: "error",
            text: "Invalid OTP",
          },
          { root: true }
        );
        return;
      }

      if (!this.account) {
        this.isLoading = true;
        const {
          bank_account_name: name,
          bank_code: bankCode,
          bank_account: accountNumber,
          country,
        } = this.config;
        this.createBankAccount({
          name,
          bankCode,
          accountNumber,
          vendor: this.vendor._id,
          countryCode: country,
        })
          .then((response) => {
            console.log(response);
            this.$store.dispatch(
              "setToast",
              {
                title: "Payout Config success!",
                type: "success",
                text: "Payout Account added successfully",
              },
              { root: true }
            );
            this.$emit("success");
            this.init();
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => (this.isLoading = false));
        return;
      }

      //update
      this.isLoading = true;
      this.updateBankAccount({
        vendor: this.vendor._id,
        returnUrl: window.location.href,
      })
        .then((response) => {
          console.log(response);
          this.$store.dispatch(
            "setToast",
            {
              title: "Payout Config success!",
              type: "success",
              text: "Payout Account Updated successfully",
            },
            { root: true }
          );
          this.$emit("success");
          this.init();
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("PAYOUT_CONFIG")) {
      this.$store.registerModule("PAYOUT_CONFIG", PayoutStoreModule);
    }

    if (!this.$store.hasModule("GATEWAY_CONFIG")) {
      this.$store.registerModule("GATEWAY_CONFIG", PaymentGatewayStoreModule);
    }
  },
  beforeDestroy() {
    // this.$store.unregisterModule("PAYOUT_CONFIG");
  },
});
