
























































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import paymentGatewayStoreModule from "@/store/modules/paymentGateway";
import { Business, Role } from "@/types";

const { mapActions: paymentActions } = createNamespacedHelpers(
  "PAYMENT_GATEWAY_DETAIL"
);
export default Vue.extend<any, any, any, any>({
  name: "PaymentGatewayForm",
  data: () => ({
    gateway: {
      title: "",
      description: "",
      sandbox_key: "",
      live_key: "",
      client_id: "",
      commissionPercentage: 0,
      status: 1,
      for_kenya: 0,
      for_australia: 0,
    },
    errors: "",
    titleRules: [(v: string) => !!v || "Title is required"],
    sandboxKeyRules: [],
    liveKeyRules: [],
    commissionPercentageRules: [
      (v: number) => !!v || "Commission %age is required",
    ],
  }),
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...paymentActions(["createPayment"]),
    validateForm() {
      const valid = (
        this.$refs.gatewayForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (
        this.$data.gateway.for_kenya == 0 &&
        this.$data.gateway.for_australia == 0
      ) {
        this.errors = "Please select atleast one country.";
        return;
      }
      if (
        this.$data.gateway.for_kenya == true ||
        this.$data.gateway.for_australia == true
      ) {
        this.errors = "";
      }
      if (!valid) return;
      this.gateway.client_id = (this.role?.business as Business)?._id;
      this.createPayment(this.gateway).then((res) => {
        if (res) {
          this.$emit("data-saved", true);
        }
      });
    },
    resetForm() {
      this.gateway = {
        title: "",
        description: "",
        sandbox_key: "",
        live_key: "",
        client_id: "",
        commissionPercentage: 0,
        status: 1,
        for_kenya: 0,
        for_australia: 0,
      };
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("PAYMENT_GATEWAY_DETAIL")) {
      this.$store.registerModule(
        "PAYMENT_GATEWAY_DETAIL",
        paymentGatewayStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("PAYMENT_GATEWAY_DETAIL");
  },
});
