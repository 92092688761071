






























































































import Vue from "vue";
import axios from "axios";

// import { createNamespacedHelpers } from "vuex";

// import paymentGatewayStoreModule from "@/store/modules/paymentGateway";
// import { Business, Role } from "@/types";

// const { mapActions: paymentActions } = createNamespacedHelpers(
//   "PAYMENT_GATEWAY_DETAIL"
// );
export default Vue.extend<any, any, any, any>({
  name: "addPaypalVendorForm",
  data: () => ({
    vendor: {
      user_id: "",
      account_type: "paypal",
      email: "",
      country: "",
      gateway_id: "614ae2af2ea46e3dbc482bd8",
      phone_number: "",
      paypal_account_id: "",
    },
    errors: "",
    showOtpForm: false,
    otp: "",
    otpRules: [(v: string) => !!v || "Please provide an otp"],

    businessEmailRules: [(v: string) => !!v || "Business Email is required"],
    countyRules: [(v: string) => !!v || "Country is required"],
    gatewayIdRules: [(v: string) => !!v || "Gate way id is required"],
    businessMobileRules: [
      (v: string) => !!v || "Phone or mobile no is required",
    ],
    paypalAccountIdRules: [
      (v: string) => !!v || "Paypal account id is required",
    ],
  }),
  mounted() {
    console.log("this.$store", this.$store);

    this.isPaypalSetupForm();
    this.resetForm();
  },
  computed: {
    // role(): Role {
    //   return this.$store.getters.role;
    // },
  },
  methods: {
    // ...paymentActions(["createPayment"]),
    validateForm() {
      const valid = (
        this.$refs.addPaypalVendorForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      console.log("in a valid form", valid);

      if (!valid) return;

      this.createVendor();
    },
    validateOtp() {
      const valid = (
        this.$refs.otpForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;
    },
    resetForm() {
      this.vendor = {
        user_id: this.$store.getters.user._id,
        email: this.$store.getters.user.email,
        country: "",
        gateway_id: "614ae2af2ea46e3dbc482bd8",
        phone_number: "",
        paypal_account_id: "",
        account_type: "paypal",
      };
      (
        this.$refs.addPaypalVendorForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    isPaypalSetupForm() {
      this.vendor.user_id = this.$store.getters.user._id;
      axios
        .get(
          process.env.VUE_APP_PAYMENTS_API_V1_URL +
            `/paymentVendor/` +
            this.vendor.user_id +
            `/paypal`
        )
        .then((response) => {
          if (response.data.success === false) {
            this.resetForm();
          } else {
            response.data.isPaypalSetup = true;
            this.vendor = {
              user_id: response.data.message.user_id,
              email: response.data.message.email,
              country: response.data.message.country,
              gateway_id: response.data.message.gateway_id,
              phone_number: response.data.message.phone_number,
              paypal_account_id: response.data.message.paypal_account_id,
              account_type: "paypal",
            };
          }
        })
        .catch((error) => {
          console.log("error=>", error);
          // this.errors = error.message;
          this.$store.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    createVendor() {
      // console.log("vendors data", this.vendor);
      this.vendor.user_id = this.$store.getters.user._id;
      console.log("url", process.env.VUE_APP_PAYMENTS_API_V1_URL);

      axios
        .post(
          process.env.VUE_APP_PAYMENTS_API_V1_URL +
            "/paymentVendor/addPaypalVendor",
          this.vendor
        )
        .then((response) => {
          console.log("response====>", response);

          console.log(response.data.status);
          if (response.data.success === false) {
            // this.errors = response.data.message;
            this.$store.dispatch(
              "setToast",
              {
                title: "Request failed!",
                type: "error",
                text: response.data.message,
              },
              { root: true }
            );
          } else {
            if (response.data.message) {
              this.$store.dispatch(
                "setToast",
                {
                  title: "Success",
                  type: "success",
                  text: "Paypal details updated successfully",
                },
                { root: true }
              );
            } else {
              this.$store.dispatch(
                "setToast",
                {
                  title: "Success",
                  type: "success",
                  text: "Vendor has Saved successfully",
                },
                { root: true }
              );
            }

            this.$emit("data-saved", true);
            this.$emit("isPaypalSetup", true);
            // this.resetForm();
          }
        })
        .catch((error) => {
          console.log("error=>", error);
          // this.errors = error.message;
          this.$store.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
});
