import { Module } from "vuex";

import { api } from "@/util/axios";
import { PaymentGateway } from "@/types";
import Vue from "vue";

const VUE_APP_PAYMENTS_API_V1_URL = process.env.VUE_APP_PAYMENTS_API_V1_URL;

type PaymentGatewayState = {
  paymentGatewayPage: {
    data: PaymentGateway[];
    success: boolean;
    total: number;
    page: number;
    last_page: number;
    isPaypalSetup: boolean;
    isStripeSetup: boolean;
    isFlutterwaveSetup: boolean;
    isMpesaSetup: boolean;
  };
};

const paymentGateway: Module<PaymentGatewayState, unknown> = {
  namespaced: true,
  state: () => ({
    paymentGatewayPage: {
      data: [],
      success: false,
      page: 0,
      last_page: 0,
      total: 0,
      isPaypalSetup: false,
      isStripeSetup: false,
      isFlutterwaveSetup: false,
      isMpesaSetup: false,
    },
  }),
  getters: {
    getPaymentGateway: (state) => (paymentGatewayId: string) =>
      state.paymentGatewayPage.data.find((c) => c._id === paymentGatewayId),
    paymentGatewayPage: (state) => state.paymentGatewayPage,
  },
  mutations: {
    SET_PAYMENT_GATEWAY_PAGE: (state, list) => {
      state.paymentGatewayPage = list;
    },
    ADD_PAYMENT_GATEWAY: (state, _payment) => {
      let idx = -1;
      state.paymentGatewayPage.data.map((a, i) => {
        if (a._id === _payment._id) idx = i;
      });
      if (idx === -1) state.paymentGatewayPage.data.push(_payment);
      else Vue.set(state.paymentGatewayPage.data, idx, _payment);
    },
    REMOVE_PAYMENT_GATEWAY: (state, paymentGateway) => {
      let idx = -1;
      state.paymentGatewayPage.data.map((a, i) => {
        if (a._id === paymentGateway._id) idx = i;
      });
      if (idx > -1) state.paymentGatewayPage.data.splice(idx, 1);
    },
  },
  actions: {
    async fetchVendorGateways(context, vendor_id: string) {
      return await api
        .get(`/gateway/${vendor_id}`, {
          baseURL: VUE_APP_PAYMENTS_API_V1_URL,
        })
        .then((response) => {
          console.log(response);
          return response.data;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    createTransaction(context, payload) {
      api.post(
        `/payment/transactions`,
        { ...payload },
        { baseURL: VUE_APP_PAYMENTS_API_V1_URL }
      );
    },
    fetchPaymentGateway(context, id) {
      api
        .get(`/gateway${id}`, { baseURL: VUE_APP_PAYMENTS_API_V1_URL })
        .then((response) => {
          response.data.isPaypalSetup = true;
          context.commit("ADD_PAYMENT_GATEWAY", response.data.paymentGateway);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchPaymentGatewayList(context, country = "Kenya") {
      api
        .get(`/gateway/${country}`, { baseURL: VUE_APP_PAYMENTS_API_V1_URL })
        .then((response) => {
          response.data.isPaypalSetup = true;
          response.data.isStripeSetup = true;
          response.data.isFlutterwaveSetup = true;
          response.data.isMpesaSetup = true;
          context.commit("SET_PAYMENT_GATEWAY_PAGE", response.data);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createPayment(context, payload) {
      return await api
        .post(`/v1/gateway`, payload, { baseURL: VUE_APP_PAYMENTS_API_V1_URL })
        .then((response) => {
          context.commit("ADD_PAYMENT_GATEWAY", response.data.data);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "PaymentGatewayGateway created",
            },
            { root: true }
          );
          return response.data.data;
        })
        .catch((error) => {
          console.log(error.response);
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updatePaymentGateway(
      context,
      data: { id: string; paymentGateway: PaymentGateway }
    ) {
      return await api
        .put(`/v1/gateway/${data.id}`, data.paymentGateway, {
          baseURL: VUE_APP_PAYMENTS_API_V1_URL,
        })
        .then((response) => {
          // context.commit("ADD_PAYMENT_GATEWAY", response.data.data);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Payment Gateway updated",
            },
            { root: true }
          );
          return response.data.message;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async deletePayment(context, id) {
      return await api
        .delete(`/v1/gateway/${id}`, { baseURL: VUE_APP_PAYMENTS_API_V1_URL })
        .then((response) => {
          context.commit("REMOVE_PAYMENT_GATEWAY", { _id: id });
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Payment Gateway deleted",
            },
            { root: true }
          );
          return response.data.message;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default paymentGateway;
