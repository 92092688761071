


























































































import Vue from "vue";
import axios from "axios";
import { Business, Role, User } from "@/types";
import paymentVendorGateway from "@/store/modules/paymentVendorGateway";
import { createNamespacedHelpers } from "vuex";
import Uploader from "@/components/fragments/uploader.vue";
import { sendOtp, verifyOtp } from "@/util/otp";

const { mapActions: _gatewayActions, mapGetters: _gatewayGetters } =
  createNamespacedHelpers("_PAYMENT_GATEWAY");

// import { createNamespacedHelpers } from "vuex";

// import paymentGatewayStoreModule from "@/store/modules/paymentGateway";
// import { Business, Role } from "@/types";

// const { mapActions: paymentActions } = createNamespacedHelpers(
//   "PAYMENT_GATEWAY_DETAIL"
// );
export default Vue.extend<any, any, any, any>({
  name: "addMpesaVendorForm",
  components: { Uploader },
  props: {
    mpesaData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    isLoading: false,
    vendor: {
      user_id: "",
      account_type: "mpesa",
      business_name: "",
      business_email: "",
      gateway_id: "614ae2af2ea46e3dbc482bd8",
      business_mobile: "",
      paybill_number: "",
      business_certificate: null,
      id_certificate: null,
      code: null,
    },
    errors: "",
    businessEmailRules: [(v: string) => !!v || "Business Email is required"],
    businessNameRules: [(v: string) => !!v || "Business Name is required"],
    businessPaybillRules: [(v: string) => !!v || "Mpesa Paybill is required"],
    fileRules: [(v: any) => !!v || "Please attach a file"],
    businessMobileRules: [
      (v: string) => !!v || "Phone or mobile no is required",
    ],
    otpRules: [(v: string) => !!v || "Confirmation code is required"],
    sendingOtp: false,
    confirmToken: "",
  }),
  watch: {
    busines: {
      handler() {
        if (this.business) {
          this.vendor.business_name = this.business.name;
          this.vendor.business_email = (this.role.user as User).email;
          this.vendor.business_mobile =
            (this.role.user as User).phoneNumber || "";
        }
      },
    },
  },
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
    business(): Business {
      return this.role.business as Business;
    },
  },
  created() {
    if (this.business) {
      this.vendor.business_name = this.business.name;
      this.vendor.business_email = (this.role.user as User).email;
      this.vendor.business_mobile = (this.role.user as User).phoneNumber || "";
    }
    if (this.mpesaData) {
      const { phoneNumber, paybillNumber } = this.mpesaData;
      this.vendor.business_mobile = phoneNumber;
      this.vendor.paybill_number = paybillNumber;
    }
  },
  methods: {
    ..._gatewayActions(["mpesaOnboard"]),
    async validateForm() {
      const valid = (
        this.$refs.addMpesaVendorForm as Element & {
          validate: () => boolean;
        }
      )?.validate();

      if (!valid) return;

      const accepted = await verifyOtp(this.vendor.code, this.confirmToken);

      if (!accepted) {
        this.$store.dispatch(
          "setToast",
          {
            title: "Invalid OTP!",
            type: "error",
            text: "Invalid OTP",
          },
          { root: true }
        );
        return;
      }
      this.createVendor();
    },
    resetForm() {
      this.vendor = {
        user_id: this.$store.getters.user._id,
        account_type: "mpesa",
        gateway_id: "614ae2af2ea46e3dbc482bd8",
        business_name: "",
        business_email: this.$store.getters.user.email,
        business_mobile: "",
        paybill_number: "",
        business_certificate: null,
        id_certificate: null,
        code: null,
      };
      (
        this.$refs.addMpesaVendorForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    reset() {
      //  this.$refs.addMpesaVendorForm.reset()
    },
    isMpesaSetupForm() {
      axios
        .get(
          process.env.VUE_APP_PAYMENTS_API_V1_URL +
            `/paymentVendor/${this.$store.getters.user._id}/mpesa`
        )
        .then((response) => {
          if (response.data.success === false) {
            this.resetForm();
          } else {
            response.data.isMpesaSetup = true;
            this.vendor = {
              user_id: response.data.message.user_id,
              gateway_id: response.data.message.gateway_id,
              account_type: "mpesa",
              business_name: response.data.message.business_name,
              business_email: response.data.message.email,
              business_mobile: response.data.message.business_mobile,
              paybill_number: "",
              business_certificate: null,
              id_certificate: null,
              code: null,
            };
          }
        })
        .catch((error) => {
          console.log("error=>", error);
          // this.errors = error.message;
          this.$store.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    createVendor() {
      const {
        business_name,
        business_email,
        business_mobile,
        paybill_number,
        business_certificate,
        id_certificate,
      } = this.vendor;
      const cert = business_certificate as unknown as Blob,
        id_doc = id_certificate as unknown as Blob;

      const data = new FormData();
      data.append("vendor", this.business._id);
      data.append("name", business_name);
      data.append("email", business_email);
      data.append("phone", business_mobile);
      data.append("country", "Kenya");
      data.append("currency", "KES");
      data.append("type", "individual");
      data.append("paybillNumber", paybill_number);
      // data.append("certificate", cert);
      // data.append("id_doc", id_doc);
      this.isLoading = true;
      this.mpesaOnboard({
        vendor: this.business._id,
        name: business_name,
        email: business_email,
        phoneNumber: business_mobile,
        country: "Kenya",
        currency: "KES",
        type: "individual",
        paybillNumber: paybill_number || "",
      })
        .then(() => {
          this.$emit("data-saved", true);
        })
        .finally(() => (this.isLoading = false));
    },
    sendOtpToPhone() {
      if (this.vendor.business_mobile) {
        this.sendingOtp = true;
        sendOtp(`${this.vendor.business_mobile}`)
          .then(({ data }) => {
            this.confirmToken = data.token;
            console.log(this.confirmToken);
            this.$store.dispatch(
              "setToast",
              {
                title: "OTP success!",
                type: "success",
                text: "Check your phone for the OTP",
              },
              { root: true }
            );
          })
          .catch((error) => {
            this.$store.dispatch(
              "setToast",
              {
                title: "Request failed!",
                type: "error",
                text: error.response?.data?.error?.message,
              },
              { root: true }
            );
          })
          .finally(() => (this.sendingOtp = false));
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("_PAYMENT_GATEWAY")) {
      this.$store.registerModule("_PAYMENT_GATEWAY", paymentVendorGateway);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("_PAYMENT_GATEWAY");
  },
});
