import { api } from "./axios";

export const sendOtp = (phoneNumber: string) => {
  return api.post("/v1/otp", { phoneNumber });
};

export const verifyOtp = (code: any, token?: string): Promise<boolean> => {
  return new Promise((resolve) => {
    api
      .post("/v1/otp/verify", { otp: code, token })
      .then(() => {
        resolve(true);
      })
      .catch((e) => {
        resolve(false);
      });
  });
};
