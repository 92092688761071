

































import { Role, SMSBundle } from "@/types";
import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import smsBundleStoreModule from "@/store/modules/smsBundle";

const { mapGetters: smsBundleGetters, mapActions: smsBundleActions } =
  createNamespacedHelpers("SMS_BUNDLE_ORDER");

const PAYMENTS_API = process.env.VUE_APP_PAYMENTS_API_V2_URL;
export default Vue.extend<any, any, any, any>({
  name: "BuySMSForm",
  props: {
    smsBundle: {
      type: Object as PropType<SMSBundle>,
      required: true,
    },
  },
  data: () => ({
    phone: "",
  }),
  computed: {
    ...smsBundleGetters(["smsBundlePage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...smsBundleActions(["createSMSBundleOrder"]),
    async sendSTK() {
      const payload = {
        businessId: this.role.business._id,
        smsBundleId: this.smsBundle._id,
        paymentMethod: "m-pesa",
      };

      this.createSMSBundleOrder(payload).then((order) => {
        if (order) {
          const url = PAYMENTS_API + "/mpesa/pay";
          fetch(url, {
            body: JSON.stringify({
              phone_number: this.phone,
              amount: this.smsBundle.cost,
              transactionRef: order._id,
            }),
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((res) => res.json())
            .then((res) => {
              if (res) {
                this.$notify({
                  title: "Success",
                  text: "Payment request sent to your phone",
                  type: "success",
                });
                this.$emit("data-saved", res);
              }
            });
        }
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SMS_BUNDLE_ORDER")) {
      this.$store.registerModule("SMS_BUNDLE_ORDER", smsBundleStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SMS_BUNDLE_ORDER");
  },
});
