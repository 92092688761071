























import { Role, User } from "@/types";
import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import paystackStoreModule from "@/store/modules/paystack";

const namesapce = "PAYSTACK_GATEWAY";
const { mapActions: paystackActions } = createNamespacedHelpers(namesapce);

export default Vue.extend({
  name: "PaystackVendorForm",
  data: () => ({
    accountNumber: "",
    bankCode: "MPESA",
    type: "mobile_money",
  }),
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...paystackActions(["createTransferRecipient"]),
    getRules(field: string) {
      return [(v: string) => !!v || `${field} is required`];
    },
    connect() {
      const valid = (
        this.$refs.form as Element & { validate: () => boolean }
      )?.validate();

      if (!valid) {
        return;
      }
      this.createTransferRecipient({
        businessId: this.role.business._id,
        userId: (this.role.user as User)._id,
        accountNumber: "0" + this.accountNumber,
        bankCode: this.bankCode,
        type: this.type,
        // authorizationCode: "",
      }).then((recipient) => {
        if (recipient) this.$emit("saved", recipient);
      });
    },
    close() {
      this.$emit("close-dialog", true);
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule(namesapce)) {
      this.$store.registerModule(namesapce, paystackStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule(namesapce);
  },
});
