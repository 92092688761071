



















































import Vue from "vue";
import paystackStoreModule from "@/store/modules/paystack";
import { createNamespacedHelpers } from "vuex";
import { Role } from "@/types";

const { mapActions: payActions, mapGetters: payGetters } =
  createNamespacedHelpers("PAYSTACK_FORM");

export default Vue.extend({
  name: "PaystackSubaccountForm",
  data: () => ({
    isLoading: false,
    confirmDialog: false,
    accountNumber: "",
    bankCode: "",
    bankNameRules: [(v: string) => !!v || "Account Holder Name is required"],
    bankAccountRules: [(v: string) => !!v || "Account Number is required"],
    bankCodeRules: [(v: string) => !!v || "Bank is required"],
    subaccountCode: "",
    loading: false,
  }),
  computed: {
    ...payGetters(["banks"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  created() {
    this.loading = true;

    this.fetchBanks("?currency=KES").then((banks) => {
      if (banks) {
        if (this.role && this.role.business.paystackSubaccountCode) {
          this.subaccountCode = this.role.business.paystackSubaccountCode;
          this.fetchSubaccount(this.subaccountCode).then((ac) => {
            if (ac) {
              this.loading = false;
              this.accountNumber = ac.account_number;
              const bank = banks.find(
                (item) => item.name === ac.settlement_bank
              );

              if (bank) this.bankCode = bank.code;
            }
          });
        } else this.loading = false;
      }
    });
  },
  methods: {
    ...payActions([
      "fetchBanks",
      "createSubaccount",
      "updateSubaccount",
      "fetchSubaccount",
    ]),
    validateForm() {
      const valid = (
        this.$refs.bankConfigForm as Element & {
          validate: () => boolean;
        }
      )?.validate();

      if (!valid) return;

      let payload = {
        businessId: this.role.business._id,
        accountNumber: this.accountNumber,
        bankCode: this.bankCode,
      };

      if (this.role.business.paystackSubaccountCode) {
        let payload = {
          accountNumber: this.accountNumber,
          bankCode: this.bankCode,
        };
        this.updateSubaccount({ code: this.subaccountCode, payload }).then(
          (ac) => {
            if (ac) {
              this.$emit("saved", ac);
              this.$store.dispatch("getLoggedInUser");
            }
          }
        );
      } else
        this.createSubaccount(payload).then((ac) => {
          if (ac) {
            this.$emit("saved", ac);
            this.$store.dispatch("getLoggedInUser");
          }
        });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("PAYSTACK_FORM")) {
      this.$store.registerModule("PAYSTACK_FORM", paystackStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("PAYSTACK_FORM");
  },
});
