






































































































import Vue from "vue";
import { Business, Payout, Role } from "@/types";
import { createNamespacedHelpers } from "vuex";

import payoutStoreModule from "@/store/modules/payout/payout";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

const { mapActions: payoutActions, mapGetters: payoutGetters } =
  createNamespacedHelpers("PAYOUT_LIST");

const formatDate = (date: string | Date) => {
  if (!date) return null;

  return moment(date).tz(tz).format("DD/MM/YYYY");
};

export default Vue.extend<any, any, any, any>({
  name: "PayoutList",
  props: {
    payoutStatus: {
      type: String,
      default: "active",
    },
    reload: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "all",
    },
  },
  data: () => ({
    headers: [
      // { text: "#", value: "payoutNo" },
      { text: "Date", value: "date" },
      { text: "Amount", value: "amount" },
      { text: "Fees", value: "fee" },
      { text: "Status", value: "status" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
    showDialog: false,
    payout: undefined as undefined | Payout,
  }),
  filters: {
    formatDate(date: string) {
      return formatDate(date);
    },
    createdDate(timestamp: number) {
      return formatDate(new Date(timestamp * 1000));
    },
  },
  watch: {
    role() {
      if (this.role) {
        this.fetchPayouts();
      }
    },
    options: {
      handler() {
        this.fetchPayouts();
      },
      deep: true,
    },
    reload() {
      if (this.reload) {
        this.fetchPayouts();
      }
    },
  },
  computed: {
    ...payoutGetters(["payoutList"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...payoutActions(["fetchPayoutList"]),
    fetchPayouts() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        const page = this.options.page || 1;
        this.fetchPayoutList(`?businessId=${bid}&page=${page}`);
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("PAYOUT_LIST")) {
      this.$store.registerModule("PAYOUT_LIST", payoutStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("PAYOUT_LIST");
  },
});
