























































import Vue from "vue";
import { Business, Role, User } from "@/types";
import PaymentGatewayModule from "@/store/modules/paymentVendorGateway";
import { createNamespacedHelpers } from "vuex";

const { mapActions: gatewayActions, mapGetters: gatewayGetters } =
  createNamespacedHelpers("_GATEWAY");

export default Vue.extend<any, any, any, any>({
  name: "createVendorForm",
  data: () => ({
    vendor: {
      vendor_id: "",
      account_type: "flutterwave",
      account_bank: "",
      account_number: "",
      business_name: "",
      business_email: "",
      business_contact: "" as string | undefined,
      split_type: "",
      split_value: "",
    },
    errors: "",
    bankNameRules: [(v: string) => !!v || "Bank Name is required"],
    accountNumberRules: [(v: string) => !!v || "Account Number is required"],
    businessNameRules: [(v: string) => !!v || "Business Name is required"],
    businessEmailRules: [(v: string) => !!v || "Business Email is required"],
    businessContactRules: [
      (v: string) => !!v || "Business contact is required",
    ],
    businessContactMobileRules: [
      (v: string) => !!v || "Business mobile no is required",
    ],
    businessMobileRules: [
      (v: string) => !!v || "Business mobile no is required",
    ],
    splitTypeRules: [(v: string) => !!v || "Split type is required"],
    splitValueRules: [(v: string) => !!v || "Split value is required"],
  }),
  mounted() {
    this.isFlutterwaveSetupForm();
    this.resetForm();
  },
  computed: {
    ...gatewayGetters(["gateways"]),
    role(): Role {
      return this.$store.getters.role;
    },
    vendorGateways(): any {
      return this.gateways;
    },
    isFlutterwaveSetup(): boolean {
      return this.gateways.isFlutterwaveSetup || false;
    },
    business(): Business {
      return this.role.business as Business;
    },
    flutterwaveGatewayAccount(): any {
      return this.gateways.flwAccount || null;
    },
    flutterwaveGateway(): any {
      return Object.values(this.gateways.gateways).find(
        (g: any) => g.type == "Flutterwave"
      );
    },
  },
  watch: {
    role() {
      const business = this.role.business as Business;
      this.vendor.business_name = business.name;
      this.getVendorGateways();
    },
    gateways() {
      if (this.isFlutterwaveSetup) {
        this.isFlutterwaveSetupForm();
      }
    },
  },
  created() {
    this.getVendorGateways();
  },
  methods: {
    ...gatewayActions([
      "flutterwaveOnBoard",
      "fetchPaymentGateways",
      "flutterwaveUpdate",
    ]),
    getVendorGateways() {
      if (this.role) {
        const vendor_id = (this.role.business as Business)._id;
        this.fetchPaymentGateways(vendor_id);
      }
    },
    validateForm() {
      const valid = (
        this.$refs.createVendorForm as Element & {
          validate: () => boolean;
        }
      )?.validate();

      if (!valid) return;
      this.createVendor();
    },
    resetForm() {
      this.vendor = {
        vendor_id: this.$store.getters.user._id,
        account_type: "flutterwave",
        account_bank: "",
        account_number: "",
        business_name: this.business.name || "",
        business_email: (this.role.user as User).email,
        business_contact: (this.role.user as User).phoneNumber,
        split_type: "",
        split_value: "",
      };
      (
        this.$refs.createVendorForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    isFlutterwaveSetupForm() {
      if (this.isFlutterwaveSetup) {
        if (this.flutterwaveGatewayAccount && this.flutterwaveGateway) {
          const { account_bank, account_number, business_name } =
            this.flutterwaveGatewayAccount;
          const { phone, email } = this.flutterwaveGateway;
          this.vendor = {
            vendor_id: this.$store.getters.user._id,
            account_type: "flutterwave",
            account_bank,
            account_number,
            business_name,
            business_email: email,
            business_contact: phone,
            split_type: "",
            split_value: "",
          };
        }
      }
    },
    createVendor() {
      if (this.role && !this.isFlutterwaveSetup) {
        const vendor_id = (this.role.business as Business)._id;
        const {
          business_email,
          business_contact,
          account_bank,
          account_number,
          business_name,
        } = this.vendor;
        this.flutterwaveOnBoard({
          email: business_email,
          account_bank,
          account_number,
          vendor_id,
          phone: business_contact,
          business_name,
        }).then((success: boolean) => {
          if (success) {
            this.$emit("data-saved", true);
            this.$emit("isFlutterwaveSetup", true);
            this.resetForm();
          }
        });
      }

      if (this.role && this.isFlutterwaveSetup) {
        const vendor_id = (this.role.business as Business)._id;
        const {
          business_email,
          business_contact,
          account_bank,
          account_number,
          business_name,
        } = this.vendor;
        const payload = {
          vendor_id,
          email: business_email,
          phone: business_contact,
          account_bank,
          account_number,
          business_name,
        };
        this.flutterwaveUpdate({ ...payload }).then((success: boolean) => {
          if (success) {
            this.$emit("data-saved", true);
            this.$emit("isFlutterwaveSetup", true);
            this.resetForm();
          }
        });
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("_GATEWAY")) {
      this.$store.registerModule("_GATEWAY", PaymentGatewayModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("_GATEWAY");
  },
});
