


























































































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import { Business, Role } from "@/types";
import PaymentGatewayForm from "./PaymentGatewayForm.vue";
import createVendorForm from "./createVendorForm.vue";
import addPaypalVendorForm from "./addPaypalVendorForm.vue";
import addMpesaVendorForm from "./addMpesaVendorForm.vue";
import addStripeVendorForm from "./addStripeVendorForm.vue";
import paymentGatewayModule from "@/store/modules/paymentVendorGateway";
import Payout from "../payouts/Payout.vue";
import PaystackSubaccountForm from "../payouts/PaystackSubaccountForm.vue";

const { mapActions: paymentActions, mapGetters: paymentGetters } =
  createNamespacedHelpers("PAYMENT_GATEWAY");
export default Vue.extend<any, any, any, any>({
  components: {
    PaymentGatewayForm,
    createVendorForm,
    addPaypalVendorForm,
    addMpesaVendorForm,
    addStripeVendorForm,
    Payout,
    PaystackSubaccountForm,
  },
  name: "PaymentGateway",
  props: ["isOnbordingComplete"],
  data: () => ({
    showGatewayDialog: false,
    showCreateVendorDialog: false,
    showaddPaypalVendorForm: false,
    showaddMpesaVendorForm: false,
    showaddStripeVendorForm: false,
    isPaypalSetup: false,
    subaccountDialog: false,
  }),
  computed: {
    ...paymentGetters(["gateways"]),
    role(): Role {
      return this.$store.getters.role;
    },
    user(): Role {
      return this.$store.getters.user;
    },
    vendor(): Business {
      return this.role.business as Business;
    },
    isFlutterwaveSetup(): boolean {
      return this.gateways.isFlutterwaveSetup || false;
    },
    isStripeSetup(): boolean {
      return this.gateways.isStripeSetup || false;
    },
    isMpesaSetup(): boolean {
      return this.gateways.isMpesaSetup || false;
    },
    mpesaGatewayData(): any {
      return this.gateways.gateways.mpesaGateway;
    },
    isPaystackSetUp(): boolean {
      return !!this.$store.getters.role?.business.paystackSubaccountCode;
    },
  },
  watch: {
    role() {
      this.getVendorGateways();
    },
  },
  created() {
    this.getVendorGateways();
  },
  methods: {
    ...paymentActions(["fetchPaymentGateways", "stripeUpdate"]),
    getVendorGateways() {
      if (this.role) {
        const vendor_id = (this.role.business as Business)._id;
        this.fetchPaymentGateways(vendor_id);
      }
    },
    updateStripe() {
      if (this.vendor) {
        this.stripeUpdate({
          vendor_id: this.vendor._id,
          returnUrl: window.location.href,
          refreshUrl: window.location.href,
        })
          .then((response) => {
            const { url } = response.data;
            window.open(url, "_blank");
          })
          .catch((error) => {
            this.$store.dispatch(
              "setToast",
              {
                title: "Request failed!",
                type: "error",
                text: error.response?.data?.error?.message,
              },
              { root: true }
            );
          });
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("PAYMENT_GATEWAY")) {
      this.$store.registerModule("PAYMENT_GATEWAY", paymentGatewayModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("PAYMENT_GATEWAY");
  },
});
