import { Module } from "vuex";

import { api } from "@/util/axios";
import { SMSBundle, Page } from "@/types";

type SMSBundleState = {
  smsBundlePage: Page<SMSBundle>;
};

const smsBundle: Module<SMSBundleState, unknown> = {
  namespaced: true,
  state: () => ({
    smsBundlePage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getSMSBundle: (state) => (smsBundleId: string) =>
      state.smsBundlePage.docs.find((c) => c._id === smsBundleId),
    smsBundlePage: (state) => state.smsBundlePage,
  },
  mutations: {
    SET_SMS_BUNDLE_PAGE: (state, list) => {
      state.smsBundlePage = list;
    },
  },
  actions: {
    fetchSMSBundleList(context, params = "") {
      api
        .get(`/v1/sms-bundle${params}`)
        .then((response) => {
          context.commit("SET_SMS_BUNDLE_PAGE", response.data.smsBundlePage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createSMSBundleOrder(context, payload) {
      return await api
        .post(`/v1/sms-bundle-order`, payload)
        .then((response) => {
          return response.data.smsBundleOrder;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default smsBundle;
