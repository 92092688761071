

























































import Vue from "vue";

import PaymentGateway from "@/components/payment/PaymentGateway.vue";
import SMSBundleForm from "@/components/settings/SMSBundleForm.vue";
import { SMSBundle } from "@/types";
import BuySMSForm from "@/components/settings/BuySMSForm.vue";
import BusinessSetting from "@/components/BusinessSetting.vue";
export default Vue.extend<any, any, any, any>({
  name: "Settings",
  components: {
    billing: () =>
      import(/* webpackChunkName: "billing" */ "@/views/AhidiBillingV2.vue"), // Billing.vue
    location: () =>
      import(/* webpackChunkName: "loaction" */ "@/views/Location.vue"),
    PaymentGateway,
    shipping: () =>
      import(/* webpackChunkName: "loaction" */ "@/views/Shipping.vue"),
    "sms-bundle-form": SMSBundleForm,
    "buy-sms-form": BuySMSForm,
    BusinessSetting,
  },
  data: () => ({
    isOnbordingComplete: false,
    buyDialog: false,
    paymentDialog: false,
    smsBundle: {},
  }),
  computed: {
    role() {
      return this.$store.getters.role;
    },
  },
  created() {
    if (location.search && location.search.includes("session_id")) {
      location.href = location.origin + "/settings";
    }
  },
  methods: {
    showPayment(smsBundle: SMSBundle) {
      this.smsBundle = smsBundle;
      this.buyDialog = false;
      this.paymentDialog = true;
    },
    closePaymentDialog() {
      this.paymentDialog = false;
      this.$store.dispatch("getLoggedInUser");
    },
  },
});
