












































































import Vue from "vue";
import { Business, Payout, Role } from "@/types";
import { createNamespacedHelpers } from "vuex";

import paystackStoreModule from "@/store/modules/paystack";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

const { mapActions: payoutActions, mapGetters: payoutGetters } =
  createNamespacedHelpers("SETTLEMENT_LIST");

const formatDate = (date: string | Date) => {
  if (!date) return null;

  return moment(date).tz(tz).format("DD/MM/YYYY");
};

export default Vue.extend({
  name: "PaystackPayoutList",
  data: () => ({
    headers: [
      { text: "Amount", value: "amount" },
      { text: "Fees", value: "fee" },
      { text: "Status", value: "status" },
      { text: "Date", value: "date" },
      //{ text: "Action", value: "action" },
    ],
    options: {} as { page: number; limit: number },
    showDialog: false,
    payout: undefined as undefined | Payout,
  }),
  filters: {
    formatDate(date: string) {
      return formatDate(date);
    },
    createdDate(timestamp: number) {
      return formatDate(new Date(timestamp * 1000));
    },
  },
  watch: {
    role() {
      if (this.role) {
        this.fetchPayouts();
      }
    },
  },
  created() {
    this.fetchPayouts();
  },
  computed: {
    ...payoutGetters(["payoutList"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...payoutActions(["fetchPayoutList"]),
    fetchPayouts() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        const page = this.options.page || 1;
        const limit = this.options.limit || 10;
        this.fetchPayoutList(`?businessId=${bid}&page=${page}&limit=${limit}`);
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SETTLEMENT_LIST")) {
      this.$store.registerModule("SETTLEMENT_LIST", paystackStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SETTLEMENT_LIST");
  },
});
